<!--
 * @Author:
 * @Description:二维码支付，公共页面
 * @Date: 2024-10-22 09:50:48
 * @LastEditors: Please set LastEditors
 * @FilePath: /rhcn-checkout-web/src/views/payment/local-qrcode.vue
-->
<template>
  <div ref="local" id="local" class="local-wrapper">
    <div class="saq-wrapper" v-loading.fullscreen="fullscreenLoading">
      <div class="content-wrapper" v-if="paymentInfo">
        <div class="logos">
          <div class="merchant-logo">
            <img v-if="paymentInfo.brandConfig && paymentInfo.brandConfig.base64Logo"
              :src="`data:image/png;base64,${paymentInfo.brandConfig.base64Logo}`" style="height: 100%;" />
          </div>
          <div class="card-logo">
            <img v-if="paymentInfo.checkoutModelResp && paymentInfo.checkoutModelResp.base64Logo"
              :src="`data:image/png;base64,${paymentInfo.checkoutModelResp.base64Logo}`" style="height: 100%;" />
          </div>
        </div>
        <div class="info-wrapper">
          <div class="info pc">
            <el-row>
              <el-col :span="12">
                <div class="item">
                  <!-- {{ $t('message.checkout.amount') }} -->
                  <div class="head">Amount :</div>
                  <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="item">
                  <div class="head">Valid untill :</div>
                  <div class="content">{{ paymentInfo.presentContext.expirationDate }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="info mobile">
            <div class="item margin-bottom-6">
              <!-- {{ $t('message.checkout.amount') }} -->
              <div class="head">Amount:</div>
              <div class="content">{{ paymentInfo.txnCurrency }} {{ paymentInfo.txnAmount }}</div>
            </div>
            <div class="item">
              <div class="head">Valid untill :</div>
              <div class="content">{{ paymentInfo.presentContext.expirationDate }}</div>
            </div>
          </div>
          <div class="qrcode">
            <div class="card" v-if="showCardName">{{ paymentInfo.checkoutModelResp.cardName }}</div>
            <div class="ticket-item" v-if="paymentInfo.presentContext.pixCopyAndPaste">
              <div class="ticket">{{ paymentInfo.presentContext.pixCopyAndPaste }}</div>
              <el-button class="button" type="text" @click="copyTicket">
                <span class="iconfont icon-rongqi"></span>
              </el-button>
            </div>
            <div class="placeholder_info">
              Please use the following app to scan the QR code to proceed with the payment: Gcash, Paymaya, ShopeePay,
              BDOPay, GrabPay, BPI, Landbank, CoinsPH, Cebuana Wallet.
            </div>
            <!-- <div class="scan">Imagem do bilhete:</div> -->
            <div class="img">
              <img :src="`data:image/png;base64,${paymentInfo.presentContext.base64Image}`" />
              <!-- <img :src="`${paymentInfo.presentContext.image_data_url}`" /> -->
            </div>
            <div class="copy">
              <!-- <div class="ticket">{{ paymentInfo.presentContext.pixCopyAndPaste }}</div> -->
              <el-button @click="download" class="button" type="primary">Download the QR code</el-button>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import FileSaver from 'file-saver';
import { toJpeg, toBlob } from 'html-to-image';

import { getLocalPaymentInfo } from '@/api/checkout';
import { copyString } from '@/utils/tools/utils';

export default {
  data() {
    return {
      fullscreenLoading: true,
      txnId: '',
      paymentInfo: null,
      showErrorMsg: false,
      showCardName: false,
    };
  },
  created() {
    this.txnId = this.$route.query.txnId;
    getLocalPaymentInfo(this.txnId).then((res) => {
      this.fullscreenLoading = false;
      if (res.respCode === '20000') {
        this.paymentInfo = res.data;
        if (res.data.brandConfig) {
          this.$store.dispatch('app/setBrandInfo', res.data.brandConfig);
        }
        if (!res.data) {
          this.showErrorMsg = true;
        }
      } else {
        this.showErrorMsg = true;
      }
    }).catch(() => {
      this.showErrorMsg = true;
      this.fullscreenLoading = false;
    });
  },
  methods: {
    copyTicket() {
      copyString(this.paymentInfo.presentContext.pixCopyAndPaste);
    },
    download() {
      toBlob(this.$refs.local)
        .then(() => {
          // Fix Safari 会显示图片失败，使用再次绘制处理
          toBlob(this.$refs.local)
            .then((blob) => {
              if (window.saveAs) {
                window.saveAs(blob, 'payment_code.jpg');
              } else {
                FileSaver.saveAs(blob, 'payment_code.jpg');
              }
            }).catch((error) => {
              console.log('download page error', error);
            });
        }).catch((error) => {
          console.log('download page error', error);
        });
    },
  },
};
</script>
<style lang="scss">
.local-wrapper {
  min-height: calc(100vh - 80px);
  background-color: white;
}

.saq-wrapper {
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;

  // height: 500px;
  .content-wrapper {
    padding: 0 15px;

    // height: 100px;
    .logos {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-logo {
        height: 50px;
      }

      .merchant-logo {
        height: 50px;
      }
    }

    .info-wrapper {
      text-align: center;
      background: #FAFAFA;
    }

    .info {
      background: #EEEEEE;
      height: 144px;

      &.mobile {
        display: none;
      }

      &.pc {
        display: block;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 144px;
      }

      .head {
        font-weight: 900;
        font-size: 18px;
        color: #27272B;
        line-height: 25px;
      }

      .content {
        font-weight: 900;
        font-size: 28px;
        color: #27272B;
        line-height: 38px;
        margin-top: 4px;
        max-width: 100%;
        white-space: nowrap;
      }
    }

    .qrcode {
      width: 660px;
      max-width: 100%;
      margin: 40px auto 0;

      .ticket-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;

        .button {
          // display: none;
          padding: 0;

          .icon-rongqi {
            font-size: 20px;
            color: var(--color-primary);
          }
        }
      }

      .ticket {
        border-radius: 2px;
        border: 1px solid #616D7A;
        padding: 0 12px;
        white-space: nowrap;
        overflow-x: scroll;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: 500;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
        height: 43px;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .card {
        font-weight: 900;
        font-size: 24px;
        color: #27272B;
        line-height: 33px;
      }

      .scan {
        margin-top: 40px;
        font-weight: 900;
        font-size: 20px;
        color: #27272B;
        line-height: 27px;
      }

      .img {
        width: 260px;
        height: 260px;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 0px;
        border: 1px solid #616D7A;
        background: #FFFFFF;

        img {
          height: 100%;
        }
      }

      .copy {
        margin-top: 30px;

        .ticket {
          border-radius: 2px 2px 2px 2px;
          border: 1px solid #616D7A;
          line-height: 42px;
          font-weight: 500;
          font-size: 16px;
          color: #27272B;
          text-align: left;
          padding: 0 12px;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button {
          width: 100%;
          // margin-top: 20px;
        }
      }
    }

    .tips {
      text-align: left;
      width: 660px;
      max-width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 36px;

      .head {
        font-weight: 900;
        font-size: 16px;
        color: #27272B;
        line-height: 22px;
      }

      .content {
        font-weight: 500;
        font-size: 16px;
        color: #616D7A;
        line-height: 22px;
        margin-top: 13px;
      }
    }

    .placeholder_info {
      padding: 20px 32px;
      background: rgba(255, 243, 243, 1);
    }

    .return {
      padding: 50px 0 130px;
      color: var(--color-primary);
      font-weight: 500;
      font-size: 16px;

      .el-link--primary {
        font-size: 16px;
        line-height: 22px;
        color: var(--color-primary);
      }

      .icon-fanhui {
        margin-right: 5px;
      }
    }
  }

  .error-msg {
    padding: 200px 15px 0;
    width: 690px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    .unknow {
      width: 134px;
      height: auto;
    }

    .tips {
      font-weight: 500;
      font-size: 18px;
      color: #616D7A;
      line-height: 25px;
      text-align: left;
      margin-top: 40px;
    }
  }
}

@media screen and(max-width: 1140px) {
  .saq-wrapper {
    .content-wrapper {
      .logos {
        height: 50px;

        .card-logo {
          height: 30px;
        }

        .merchant-logo {
          height: 30px;
        }
      }

      .info {
        height: 70px;

        &.pc {
          display: none;
        }

        &.mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .item {
          height: auto;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 10px;

          .head {
            font-size: 12px;
          }

          .content {
            font-size: 16px;
            line-height: 22px;
          }
        }

        // .head {
        //   font-size: 24px;
        //   line-height: 33px;
        // }
        // .content {
        //   font-size: 32px;
        //   line-height: 44px;
        //   margin-top: 0;
        // }
      }

      .qrcode {
        margin-top: 20px;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .ticket {
          font-size: 12px;
          line-height: 17px;
        }

        .card {
          font-size: 14px;
          line-height: 19px;
        }

        .scan {
          margin-top: 15px;
          font-size: 14px;
          line-height: 19px;
        }

        .copy {
          margin-top: 15px;
        }
      }

      // .qrcode {
      //   margin-top: 30px;
      //   .card {
      //     font-size: 28px;
      //     line-height: 38px;
      //   }
      //   .scan {
      //     font-size: 22px;
      //     line-height: 30px;
      //     margin-top: 5px;
      //   }
      //   .img {
      //     height: 290px;
      //   }
      //   .copy {
      //     margin-top: 50px;
      //     .ticket {
      //       line-height: 58px;
      //       font-size: 22px;
      //     }
      //     .button {
      //       height: 60px;
      //       font-size: 24px;
      //     }
      //   }
      // }
      .tips {
        padding-top: 20px;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .head {
          font-size: 12px;
          line-height: 17px;
        }

        .content {
          font-size: 12px;
          line-height: 17px;
        }
      }

      // .return {
      //   font-size: 24px;
      //   line-height: 33px;
      //   .button {
      //     font-size: 24px;
      //     line-height: 33px;
      //   }
      // }
    }
  }
}
</style>
